import React from 'react';
import { useSelector } from 'react-redux';
import { formatDate } from '../../utils/date-utils';

import heroStyles from './hero.module.scss';

const Hero = ({ title, description, date, topImage }) => {
  const { windowWidth } = useSelector(({ global }) => global);
  const isMobile = windowWidth < 630;

  return (
    <div className={heroStyles.hero}>
      <div className={heroStyles.heroInfo}>
        <h1>{title}</h1>
        <h3>{description}</h3>
        {date && <h6>{formatDate(date)}</h6>}
      </div>
      {!isMobile && (
        <div
          role="img" 
          title={topImage.alt || ''}
          aria-label={topImage.alt || ''}
          className={heroStyles.heroImage}
          style={{ backgroundImage: `url(${topImage.url})` }}
        />
      )}
    </div>
  );
};

export default Hero;
