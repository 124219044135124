import { Divider } from 'antd';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import debounce from 'lodash/debounce';
import React, { useState, useEffect, useRef } from 'react';

import styles from './toc.module.scss';

const NAVBAR_HEIGHT = 84;

const TOC = ({ before, after }) => {
  const tocEl = useRef(null);
  const [toc, setTOC] = useState([]);

  const onItemClick = (e, id) => {
    e && e.preventDefault();
    const el = document.getElementById(id);
    if (el) {
      el.scrollIntoView();
      window.scrollTo(0, window.scrollY - 100);
    }
  };

  const createTOC = () => {
    const staticPage = document.getElementById('page-center');
    if (staticPage) {
      const h2 = [].slice.apply(staticPage.querySelectorAll('h2'));
      const tocContent = h2.map(el => ({
        id: el.getAttribute('id'),
        text: el.textContent,
      }));
      setTOC(tocContent);
    }
  };

  useEffect(function createTableOfContent() {
    if (['ready', 'complete'].includes(document.readyState)) {
      createTOC();
    } else {
      window.addEventListener('load', createTOC);
    }
  }, []);

  useEffect(function onScroll() {
    const onWindowScroll = debounce(() => {
      const pageCenter = document.getElementById('page-center');
      if (!isEmpty(toc) && pageCenter && tocEl) {
        const tocDiffFromPageCenter = window.pageYOffset - pageCenter.offsetTop + NAVBAR_HEIGHT;
        if (tocDiffFromPageCenter > 0 && (window.pageYOffset - pageCenter.offsetHeight < 0)) {
          if (!tocEl.current.classList.contains(styles.tocContainerFixed)) {
            tocEl.current.classList.add(styles.tocContainerFixed);
          }
        } else {
          if (tocEl.current.classList.contains(styles.tocContainerFixed)) {
            tocEl.current.classList.remove(styles.tocContainerFixed);
          }
        }
      }
    }, 80);
    onWindowScroll();
    window.addEventListener('scroll', onWindowScroll);
    return () => {
      window.removeEventListener('scroll', onWindowScroll);
    };
  }, [tocEl, toc]);

  if (isEmpty(toc)) {
    return null;
  }
  return (
    <div ref={tocEl} className={styles.tocContainer}>
      {before}
      <div className={styles.container}>
        <div className={styles.title}>Jump to:</div>

        <Divider className={styles.divider} />

        <ul className={styles.containerList}>
          {toc.map(({ id, text }) => (
            <li key={id}>
              <a
                href={`#${id}`}
                name={`#${id}`}
                className="ant-dropdown-link"
                onClick={e => onItemClick(e, id)}
              >
                {text}
              </a>
            </li>
          ))}
        </ul>
      </div>
      {after}
    </div>
  );
};

PropTypes.defaultProps = {
  before: PropTypes.node,
  after: PropTypes.node,
};

PropTypes.propTypes = {
  before: null,
  after: null,
};

export default TOC;
