import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import debounce from 'lodash/debounce';
import startCase from 'lodash/startCase';
import takeRight from 'lodash/takeRight';
import { useSelector } from 'react-redux';
import { IoLogoWhatsapp } from 'react-icons/io';
import { AiOutlineTwitter } from 'react-icons/ai';
import { TiSocialFacebook } from 'react-icons/ti';
import React, { useEffect, useRef, useCallback } from 'react';

import SEO from '../../components/seo';
import TOC from '../../components/toc';
import TemplateLayout from '../layout';
import Hero from '../../components/hero';
import ReachText from '../../components/reach-text';
import Breadcrumb from '../../components/breadcrumb';
import TagsSidebar from '../../components/tags-sidebar';
import CategoriesSidebar from '../../components/categories-sidebar';
import { shareFacebook, shareTwitter, shareWhatsapp } from '../../utils/share-utils';

import styles from './index.module.scss';

const Social = ({ fullPageURL, title }) => (
  <>
    <div
      className={styles.socialFacebook}
      onClick={() => shareFacebook(`u=${fullPageURL}&text=${title}`)}
    >
      <TiSocialFacebook />
    </div>
    <div
      className={styles.socialTwitter}
      onClick={() => shareTwitter(`url=${fullPageURL}&text=${title}`)}
    >
      <AiOutlineTwitter />
    </div> 
    <div
      className={styles.socialWhatsapp}
      onClick={() => shareWhatsapp(`text=${fullPageURL}`)}
    >
      <IoLogoWhatsapp />
    </div>
  </>
);

const CategoryPost = ({ pageContext }) => {
  const sidebarEl = useRef(null);
  const pageCenterEl = useRef(null);

  const { navbarHeight, windowWidth } = useSelector(({ global }) => global);

  const data = get(pageContext, 'data', {});
  const linkedPages = get(pageContext, 'linkedPages', []);

  const year = get(data, 'year', '');
  const date = get(data, 'page_date', '');
  const tags = get(data, '_meta.tags', []);
  const category = get(data, 'category', '');
  const topImage = get(data, 'top_image', '');
  const title = get(data, 'title.[0].text', '');
  const description = get(data, 'description.[0].text', '');

  const yearStr = year.toString();
  const pageBreadcrumb = takeRight(title, yearStr.length).join('') === yearStr ? title : `${title} - ${year}`;

  const fullPageURL = encodeURIComponent(typeof window !== `undefined` ? window.location.href : '');

  const shouldRenderSidebar = !isEmpty(linkedPages) || !isEmpty(tags);
  const isTOCVisible =  windowWidth > 767;

  const pageCenterStyle = (() => {
    if (shouldRenderSidebar || !isTOCVisible) return {};
    return { maxWidth: 'calc(100% - 190px)' };
  })();

  useEffect(function onScroll() {
    if (shouldRenderSidebar) {
      const onWindowScroll = debounce(() => {
        if (pageCenterEl && sidebarEl) {
          const sidebarDiffFromPageCenter = window.pageYOffset - pageCenterEl.current.offsetTop + navbarHeight;
          const nav = document.getElementsByTagName('nav')[0];
          if (sidebarDiffFromPageCenter > 0 && (window.pageYOffset - pageCenterEl.current.offsetHeight < 0)) {
            if (!sidebarEl.current.classList.contains(styles.pageSidebarFixed)) {
              sidebarEl.current.classList.add(styles.pageSidebarFixed);
              pageCenterEl.current.classList.add(styles.pageContainerFixed);
              if (nav) {
                sidebarEl.current.style.right = `${nav.offsetLeft + 10}px`;
              }
            }
          } else {
            if (sidebarEl.current.classList.contains(styles.pageSidebarFixed)) {
              sidebarEl.current.classList.remove(styles.pageSidebarFixed);
              pageCenterEl.current.classList.remove(styles.pageContainerFixed);
              if (nav) {
                sidebarEl.current.style.right = 'initial';
              }
            }
          }
        }
      }, 80);
      onWindowScroll();
      window.addEventListener('scroll', onWindowScroll);
      return () => {
        window.removeEventListener('scroll', onWindowScroll);
      };
    }
  }, [pageCenterEl, sidebarEl, navbarHeight, shouldRenderSidebar]);

  useEffect(function onResize() {
    if (shouldRenderSidebar) {
      const onWindowResize = debounce(() => {
        if (pageCenterEl && sidebarEl) {
          const sidebarDiffFromPageCenter = window.pageYOffset - pageCenterEl.current.offsetTop + navbarHeight;
          const nav = document.getElementsByTagName('nav')[0];
          if (nav) {
            sidebarEl.current.style.right = sidebarDiffFromPageCenter > 0 ? `${nav.offsetLeft + 10}px` : 'initial';
          }
        }
      }, 80);
      onWindowResize();
      window.addEventListener('resize', onWindowResize);
      return () => {
        window.removeEventListener('resize', onWindowResize);
      };
    }
  }, [pageCenterEl, sidebarEl, navbarHeight, shouldRenderSidebar]);

  return (
    <TemplateLayout>
      <SEO 
        title={`Datayears | ${title}`} 
        description={description}
        image={topImage.url}
      />

      <Breadcrumb
        items={[
          { name: 'Home', linkTo: '/' },
          { name: 'Categories', linkTo: `/categories` },
          { name: startCase(category), linkTo: `/${category}` },
          { name: pageBreadcrumb },
        ]}
      />

      <Hero
        title={title}
        description={description}
        date={date}
        topImage={topImage}
      />

      <div
        id="page-center"
        ref={pageCenterEl}
        className={styles.pageContainer}
      >
        <aside className={styles.pageAside}>
          <TOC
            before={
              <div className={styles.social}>
                <Social title={title} fullPageURL={fullPageURL} />
              </div>
            }
          />
        </aside>

        <div className={styles.pageCenter} style={pageCenterStyle}>
          <ReachText
            input={data.body}
            renderPageTldr
            renderPageIntro
            renderPageList
            renderPagePieChart
            renderPageBarChart
            renderPageTable
            renderPageClose
          />
        </div>

        {shouldRenderSidebar && (
          <div ref={sidebarEl} className={styles.pageSidebar}>
          {!isEmpty(linkedPages) && (
            <CategoriesSidebar
              categoryPrefix="More on"
              nodesByCategory={{ [category]: linkedPages }}
            />
          )}
          {!isEmpty(tags) && (
            <TagsSidebar tags={tags} />
          )}
          </div>
        )}
       
      </div>

      <div className={styles.socialFixed}>
        <Social title={title} fullPageURL={fullPageURL} />
      </div>
    </TemplateLayout>
  );
};

export default CategoryPost;
