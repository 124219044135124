import React from 'react';
import { Link } from 'gatsby';
import { Breadcrumb } from 'antd';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import kebabCase from 'lodash/kebabCase';

import styles from './breadcrumb.module.scss';

const CustomBreadcrumb = ({ items }) => {
  if (isEmpty(items)) {
    return null;
  }
  return (
    <Breadcrumb className={styles.breadcrumb}>
      {items.map(({ linkTo, name }) => (
        <Breadcrumb.Item key={kebabCase(name)}>
          {linkTo ? <Link to={linkTo}>{name}</Link> : name}
        </Breadcrumb.Item>
      ))}
    </Breadcrumb>
  );
};

CustomBreadcrumb.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    linkTo: PropTypes.string,
    name: PropTypes.string,
  })),
};

CustomBreadcrumb.defaultProps = {
  items: [],
};

export default CustomBreadcrumb;
